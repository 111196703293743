import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/en/chapter_4/sub_2/slide1';
import WrapperMobile from 'src/slides/mobile/en/chapter_4/sub_2/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Who Mikhail Tukhachevsky was | The Battle of Warsaw" lang="en" description="Portrait of the Bolshevik Napoleon who was supposed to set fire to Europe." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Who Mikhail Tukhachevsky was | The Battle of Warsaw" lang="en" description="Portrait of the Bolshevik Napoleon who was supposed to set fire to Europe." />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
